const CDN = "https://oss-xiaoan.oss-cn-shenzhen.aliyuncs.com/test_room";
export default [
  {
    name: "base",
    data: {},
    items: [
      {
        name: "googleHomeLedMaskTexture",
        source: CDN + "/assets/googleHomeLedMask.png",
        type: "texture",
      },
      {
        name: "googleHomeLedsModel",
        source: CDN + "/assets/googleHomeLedsModel.glb",
        type: "model",
      },

      {
        name: "loupedeckButtonsModel",
        source: CDN + "/assets/loupedeckButtonsModel.glb",
        type: "model",
      },

      {
        name: "topChairModel",
        source: CDN + "/assets/topChairModel.glb",
        type: "model",
      },

      {
        name: "coffeeSteamModel",
        source: CDN + "/assets/coffeeSteamModel.glb",
        type: "model",
      },

      {
        name: "elgatoLightModel",
        source: CDN + "/assets/elgatoLightModel.glb",
        type: "model",
      },

      {
        name: "threejsJourneyLogoTexture",
        source: CDN + "/assets/logo.png",
        type: "texture",
      },

      {
        name: "pcScreenModel",
        source: CDN + "/assets/pcScreenModel.glb",
        type: "model",
      },

      {
        name: "macScreenModel",
        source: CDN + "/assets/macScreenModel.glb",
        type: "model",
      },

      {
        name: "bakedDayTexture",
        source: CDN + "/assets/bakedDay.jpg",
        type: "texture",
      },
      {
        name: "bakedNightTexture",
        source: CDN + "/assets/bakedNight.jpg",
        type: "texture",
      },
      {
        name: "bakedNeutralTexture",
        source: CDN + "/assets/bakedNeutral.jpg",
        type: "texture",
      },
      {
        name: "lightMapTexture",
        source: CDN + "/assets/lightMap.jpg",
        type: "texture",
      },
      { name: "roomModel", source: CDN + "/assets/roomModel.glb" },
    ],
  },
];
